import React, { useContext } from 'react';
import { PackagePickerSettingsParams } from '../../settingsParams';

const SettingsParamsContext = React.createContext<PackagePickerSettingsParams>({} as PackagePickerSettingsParams);

type SettingsParamsProviderProps = {
  settingsParams: PackagePickerSettingsParams;
};

export const SettingsParamsProvider: React.FC<SettingsParamsProviderProps> = ({ children, settingsParams }) => {
  return <SettingsParamsContext.Provider value={settingsParams}>{children}</SettingsParamsContext.Provider>;
};

export const useSettingsParams = () => useContext(SettingsParamsContext);
