import React, { FC } from 'react';
import { WidgetProps } from 'yoshi-flow-editor-runtime';
import { List, ListProps } from '../../PackagePicker/Widget/List';
import { SettingsParamsProvider } from '../../PackagePicker/Settings/context/SettingsParamsContext';
import settingsParams from '../settingsParams';
import { StylesParamsProvider } from '../../PackagePicker/Settings/context/StylesParamsContext';
import stylesParams from '../stylesParams';

const Widget: FC<WidgetProps<ListProps>> = (props) => {
  return (
    <StylesParamsProvider stylesParams={stylesParams}>
      <SettingsParamsProvider settingsParams={settingsParams}>
        <List {...props} dataHook="PlanList-wrapper" />
      </SettingsParamsProvider>
    </StylesParamsProvider>
  );
};

export default Widget;
