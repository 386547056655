import React from 'react';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { st, classes } from './PlanName.st.css';
import { useStylesParams } from '../../Settings/context/StylesParamsContext';

interface Props {
  name: string;
  highlighted: boolean;
}

export const PlanName: React.FC<Props> = ({ name, highlighted }) => {
  const stylesParams = useStylesParams();
  const styles = useStyles();
  const showPlanName = styles.get(stylesParams.showPlanName);

  if (!showPlanName) {
    return null;
  }

  return <h2 className={st(classes.planTitle, { highlighted })}>{name}</h2>;
};
