import React from 'react';
import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import { useSettings } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import { Plan } from '../Plan';
import { classes } from './PlanList.st.css';
import { getHighlightedPlanId } from './get-highlighted-plan-id';
import { useSettingsParams } from '../../Settings/context/SettingsParamsContext';

interface Props {
  plans: PublicPlan[];
  navigateToCheckout: (plan: PublicPlan) => void;
  showDemoHighlight?: boolean;
  isMobile?: boolean;
}

export const PlansList: React.FC<Props> = ({ plans, showDemoHighlight, navigateToCheckout, isMobile }) => {
  const settingsParams = useSettingsParams();
  const settings = useSettings();

  const highlightedPlanId = getHighlightedPlanId(plans, {
    showHighlightedPlan: settings.get(settingsParams.showHighlightedPlan) as boolean,
    highlightedPlanId: settings.get(settingsParams.highlightedPlan) as string,
    showDemoHighlight,
  });

  const hiddenPlans = (settings.get(settingsParams.hiddenPlans) as string).split(',');
  const planOrder = (settings.get(settingsParams.planOrder) as string).split(',');
  const orderedPlans = orderPlans(plans, planOrder, hiddenPlans);
  const recurringPlansExist = orderedPlans.filter((p) => p.paymentOptions?.recurring).length > 0;
  const freeTrialDaysExist = orderedPlans.filter((p) => p.paymentOptions?.freeTrialDays).length > 0;
  const anyBenefits = !!orderedPlans.find((p) => p.details?.benefits?.length);

  return (
    <ul tabIndex={-1} className={classes.container}>
      {orderedPlans.map((plan) => (
        <li key={plan.id} tabIndex={1}>
          <Plan
            anyBenefits={anyBenefits}
            highlighted={highlightedPlanId === plan.id}
            plan={plan}
            recurringPlansExist={recurringPlansExist}
            freeTrialDaysExist={freeTrialDaysExist}
            isMobile={isMobile}
            onClick={() => navigateToCheckout(plan)}
          />
        </li>
      ))}
    </ul>
  );
};

function isTruthy<T>(x: T | undefined | null): x is T {
  return !!x;
}

const orderPlans = (plans: PublicPlan[], planOrder: string[], hiddenPlans: string[]) => {
  const ordered = planOrder.map((id) => plans.find((p) => p.id === id)).filter(isTruthy);
  const unordered = plans.filter((p) => !planOrder.includes(p.id!));
  return [...ordered, ...unordered].filter((p) => !hiddenPlans.includes(p.id!));
};
